.header{
     width: 100%;
     height: 80px;
     background: #26282A;
     position: sticky;
     top: 0;
     z-index: 9999;
}
.header .header_left{
     width: 35%;
     height: 80px;
     display: flex;
     align-items: center;
}
.header .header_left img{
     width: 12vw;
}
.header .header_right{
     width: 65%;
     height: 80px;
     color: #FFF;
     display: flex;
     align-items: center;
}
.header .header_right p{
     width: 15vh;
     height: 80px;
     line-height: 80px;
     text-align: center;
     color: #FFF;
}
 .P_before{
     background: #007bff;
 }
  .P_after{
     border-bottom: 1px solid transparent;
 }


.content{
     width: 100%;
     height: auto;
     background: url(../images//bg.png) no-repeat;
     background-size: 100%;
}
.content .content_one{
     width: 80%;
     height: auto;
     margin: 0 auto;
     position: relative;
}
.content .content_one .content_one_btn{
     width: 50%;
     display: flex;
     position: absolute;
     top: 60%;
}
.content_one_btn_div{
     width: 45%;
     height: 70px;
     line-height: 70px;
     text-align: center;
     background-color: rgba(235, 233, 253, 0.5); 
     justify-content: center;
     align-items: center;
     font-size: 25px;
     display: flex;
     margin-right: 30px;
     border: 1px solid #FFF;
     border-radius: 50px;
     -webkit-border-radius: 50px;
     -moz-border-radius: 50px;
     -ms-border-radius: 50px;
     -o-border-radius: 50px;
}

.footer{
     width: 100%;
     height: 120px;
     background: #FFFFFF;
     text-align: center;
     margin: 30px auto 0 auto;
     line-height: 30px;
     color: #333;
}


