*{margin: 0;padding: 0;}
.bg{
     width: 100%;
     height: auto;
     background: #F9F9F9;
     overflow: hidden;
}
.bg_content{
     width: 70%;
     height: 540px;
     border: 1px solid red;
     margin: 20px auto;
     background: #FFF;
}
.bg_content_one{
     display: flex;
     align-items: center;
}
.bg_content_one p{
     margin: 20px 20px 20px 50px;
}
.bg_content_one .ipt1{
     width: 200px;
     height: 30px;
     background: #F6F5FF;
     border:none;
     text-indent: 10px;
     border-radius: 5px;
     -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
     -ms-border-radius: 5px;
     -o-border-radius: 5px;
}
.bg_content_one .ipt2{
     width: 100px;
     height: 30px;
     background: #F6F5FF;
     border:none;
     text-indent: 10px;
     margin-right: 10px;
     border-radius: 5px;
     -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
     -ms-border-radius: 5px;
     -o-border-radius: 5px;
}

.bg_amount{
     display: flex;
     height: 150px;
}
.bg_amount p{
     margin: 20px 20px 20px 50px;
}
.bg_amount .amount_other{
     width: 100px;
     height: 60px;
     border: 2px solid #9878ce;
     background: #f7f5ff;
     margin-bottom: 5px;
     margin-right: 10px;
     border-radius: 10px;
     -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
     -ms-border-radius: 10px;
     -o-border-radius: 10px;
}
.bg_amount .amount_other .amount_other_top{
     margin: 2px 0;
     display: flex;
     align-items: baseline;
     justify-content: center;
}
.bg_amount .amount_other .amount_other_top p{
     font-size: 16px;
     color: #000;
}
.bg_amount .amount_other p{
     margin: 0;padding: 0;
     font-size: 14px;
     color: #908f97;
     text-align: center;
}
.bg_amount .amount_others{
     width: 100px;
     height: 60px;
     border: 2px solid transparent;
     background: #f6f5ff;
     margin-bottom: 5px;
     margin-right: 10px;
     border-radius: 10px;
     -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
     -ms-border-radius: 10px;
     -o-border-radius: 10px;
}
.bg_amount .amount_others .amount_other_top{
     margin: 2px 0;
     display: flex;
     align-items: baseline;
     justify-content: center;
     
}
.bg_amount .amount_others .amount_other_top p{
          font-size: 16px;
          color: #000;
     }
.bg_amount .amount_others p{
     margin: 0;padding: 0;
     font-size: 14px;
     color: #908f97;
     text-align: center;
}

.bg_paymentMethod{
     display: flex;
     align-items: center;
}
.bg_paymentMethod p{
     margin: 20px 20px 20px 50px;
}
.bg_paymentMethod .amount_other{
     width: 100px;
     height: 40px;
     border: 2px solid #9878ce;
     background: #f7f5ff;
     display: flex;
     justify-content: center;
     align-items: center;
     margin-bottom: 5px;
     margin-right: 10px;
     border-radius: 10px;
     -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
     -ms-border-radius: 10px;
     -o-border-radius: 10px;
}

.bg_paymentMethod .amount_other p{
     margin: 0;
     padding: 0;
     font-size: 14px;
     color: #908f97;
     text-align: center;
}
.bg_paymentMethod .amount_others{
     width: 100px;
     height: 40px;
     background: #f6f5ff;
     border: 2px solid transparent;
     margin-bottom: 5px;
     margin-right: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 10px;
     -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
     -ms-border-radius: 10px;
     -o-border-radius: 10px;
}
.bg_paymentMethod .amount_others p{
     margin: 0;padding: 0;
     font-size: 14px;
     color: #908f97;
     text-align: center;
}

.bg_tips{
     margin: 20px 20px 20px 50px;
}
.bg_tips p{
     font-size: 12px;
     color: #908f97;
     margin-top: 3px;
}

.bg_btnfooter .btn{
     width: 300px;
     height: 50px;
     border: none;
     color: #FFF;
     font-size: 16px;
     margin-left: 50px;
     background: linear-gradient(to right,#0079ff, #f361d9);
     border-radius: 50px;
     -webkit-border-radius: 50px;
     -moz-border-radius: 50px;
     -ms-border-radius: 50px;
     -o-border-radius: 50px;
}
